import { useCallback } from 'react';
import { type AxiosRequestConfig } from 'axios';
import {
  CreatePartnerRequest,
  NoContentResult,
  GetPartnersResponse,
  GetPartnerByIdResponse,
  UpdatePartnerRequest,
  GetPartnerHistoricLicenseDataResponse,
  GetPartnersForLookupResponse,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import {
  TGetPartnerLicensesHistoryMethod,
  TGetPartnersMethod,
  TGetPartnersForLookupMethod,
} from './interfaces';

const usePartnersAPI = () => {
  const { partnersAPIClient } = useApiClients();

  const createPartner = useCallback(
    (params: CreatePartnerRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnersAPIClient
      .apiV1PartnersPost(params, options)
      .then(dataExtractor<NoContentResult>()),
    [partnersAPIClient],
  );

  const getPartners = useCallback<TGetPartnersMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        countryFilter = undefined,
        typeFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return partnersAPIClient.apiV1PartnersGet(
        page,
        pageLength,
        partnerId,
        countryFilter,
        typeFilter,
        undefined, // TODO NEXTGEN-4703 movedFromPartnerId
        undefined, // TODO NEXTGEN-4703 allMovedPartners
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetPartnersResponse>());
    },
    [partnersAPIClient],
  );

  const getPartnerById = useCallback(
    (partnerId: string, options?: AxiosRequestConfig)
      : Promise<GetPartnerByIdResponse> => partnersAPIClient
      .apiV1PartnersPartnerIdGet(partnerId, options)
      .then(dataExtractor<GetPartnerByIdResponse>()),
    [partnersAPIClient],
  );

  const updatePartner = useCallback(
    (updatePartnerRequest?: UpdatePartnerRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnersAPIClient
      .apiV1PartnersPut(updatePartnerRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnersAPIClient],
  );

  const getPartnerLicensesHistory = useCallback<TGetPartnerLicensesHistoryMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return partnersAPIClient.apiV1PartnersGetHistoricLicenseDataGet(
        page,
        pageLength,
        partnerId,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetPartnerHistoricLicenseDataResponse>());
    },
    [partnersAPIClient],
  );

  const getPartnersForLookup = useCallback<TGetPartnersForLookupMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        searchQuery = undefined,
      } = params;
      return partnersAPIClient.apiV1PartnersGetForLookupGet(
        page,
        pageLength,
        undefined, // todo partnerTypes
        searchQuery,
        axiosRequestConfig,
      ).then(dataExtractor<GetPartnersForLookupResponse>());
    },
    [partnersAPIClient],
  );

  return {
    createPartner,
    getPartners,
    getPartnerById,
    updatePartner,
    getPartnerLicensesHistory,
    getPartnersForLookup,
  };
};

export default usePartnersAPI;
